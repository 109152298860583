<template>
  <v-container fluid>
    <div>
      <v-container fluid>
        <v-layout row>
          <v-expansion-panel v-model="panel" expand>
            <v-expansion-panel-content class="mycustomExpansionPanel">
              <template v-slot:actions>
                <v-icon large color="primary">$vuetify.icons.expand</v-icon>
              </template>
              <template v-slot:header>
                <div>
                  <v-icon color="primary">search</v-icon>
                  <span class="pl-2 subheading">Form-fillup Report</span>
                </div>
              </template>
              <v-layout row wrap align-center>
                <v-flex>
                  <v-card>
                    <v-container fluid grid-list-md>
                      <v-layout row wrap>
                        <v-flex>
                          <v-card style="padding-top:20px">
                            <v-card-text>
                              <v-menu
                                v-model="apply_from_status"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                lazy
                                transition="scale-transition"
                                offset-y
                                full-width
                                min-width="290px"
                              >
                                <template v-slot:activator="{ on }">
                                  <v-text-field
                                    style="padding:0px 10px"
                                    v-model="searchParam.apply_from_date"
                                    label="From Date (Form Fill-up Apply)"
                                    prepend-icon="event"
                                    readonly
                                    v-on="on"
                                    clearable
                                  ></v-text-field>
                                </template>
                                <v-date-picker
                                  v-model="searchParam.apply_from_date"
                                  @input="apply_from_status = false"
                                ></v-date-picker>
                              </v-menu>
                              <v-menu
                                v-model="apply_to_status"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                lazy
                                transition="scale-transition"
                                offset-y
                                full-width
                                min-width="290px"
                              >
                                <template v-slot:activator="{ on }">
                                  <v-text-field
                                    style="padding:0px 10px"
                                    v-model="searchParam.apply_to_date"
                                    label="To Date (Form Fill-up Apply)"
                                    prepend-icon="event"
                                    readonly
                                    v-on="on"
                                    clearable
                                  ></v-text-field>
                                </template>
                                <v-date-picker
                                  v-model="searchParam.apply_to_date"
                                  @input="apply_to_status = false"
                                ></v-date-picker>
                              </v-menu>
                            </v-card-text>
                          </v-card>
                        </v-flex>
                        <v-flex>
                          <v-card style="padding-top:20px">
                            <v-card-text>
                              <v-menu
                                v-model="confirm_from_status"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                lazy
                                transition="scale-transition"
                                offset-y
                                full-width
                                min-width="290px"
                              >
                                <template v-slot:activator="{ on }">
                                  <v-text-field
                                    style="padding:0px 10px"
                                    v-model="searchParam.confirm_from_date"
                                    label="From Date (College Confirmation)"
                                    prepend-icon="event"
                                    readonly
                                    v-on="on"
                                    clearable
                                  ></v-text-field>
                                </template>
                                <v-date-picker
                                  v-model="searchParam.confirm_from_date"
                                  @input="confirm_from_status = false"
                                ></v-date-picker>
                              </v-menu>
                              <v-menu
                                v-model="confirm_to_status"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                lazy
                                transition="scale-transition"
                                offset-y
                                full-width
                                min-width="290px"
                              >
                                <template v-slot:activator="{ on }">
                                  <v-text-field
                                    style="padding:0px 10px"
                                    v-model="searchParam.confirm_to_date"
                                    label="To Date (College Confirmation)"
                                    prepend-icon="event"
                                    readonly
                                    v-on="on"
                                    clearable
                                  ></v-text-field>
                                </template>
                                <v-date-picker
                                  v-model="searchParam.confirm_to_date"
                                  @input="confirm_to_status = false"
                                ></v-date-picker>
                              </v-menu>
                            </v-card-text>
                          </v-card>
                        </v-flex>
                      </v-layout>
                    </v-container>
                  </v-card>
                </v-flex>
                <!-- ============================== -->
                <v-flex xs12 sm12 md12>
                  <v-card class="px-3">
                    <v-layout row wrap align-center class="py-3">
                      <v-responsive class="mx-left">
                        <v-select
                          class="ma-2"
                          v-model="searchParam.installment_id"
                          :item-text="installmentText"
                          item-value="id"
                          :items="getSchema"
                          menu-props="auto"
                          label="Chose your installment"
                          prepend-icon="edit"
                          clearable
                        ></v-select>
                      </v-responsive>

                      <v-responsive class="mx-left">
                        <v-select
                          class="ma-2"
                          v-model="searchParam.report_name"
                          item-text="name"
                          item-value="tag"
                          :items="ReportWise"
                          menu-props="auto"
                          label="Chose your report"
                          prepend-icon="edit"
                          v-validate="'required'"
                          data-vv-name="tag"
                          :error-messages="errors.collect('tag')"

                        ></v-select>
                      </v-responsive>

                      <v-responsive
                        v-if="paper_type_status==true"
                        class="mx-left"
                        max-width="25%"
                      >
                        <v-select
                          class="ma-2"
                          v-model="searchParam.paper_type"
                          item-text="paper_type"
                          item-value="id"
                          :items="paperType"
                          menu-props="auto"
                          label="Select Paper Type"
                          multiple
                          prepend-icon="edit"
                          clearable
                        ></v-select>
                      </v-responsive>
                      <v-responsive class="mx-left" max-width="25%" v-if="data_source_status==true">
                        <v-select
                          class="ma-2"
                          v-model="searchParam.data_source"
                          item-text="name"
                          item-value="tag"
                          :items="sourceData"
                          menu-props="auto"
                          label="Select Data Source"
                          prepend-icon="edit"
                          v-validate="'required'"
                          data-vv-name="tag"
                          :error-messages="errors.collect('tag')"
                        ></v-select>
                      </v-responsive>

                      <v-responsive class="mx-left" max-width="25%">
                        <v-select
                          class="ma-2"
                          v-model="searchParam.document_type"
                          item-text="name"
                          item-value="tag"
                          :items="getExportType"
                          menu-props="auto"
                          label="Select Output to"
                          prepend-icon="edit"
                          required
                        ></v-select>
                      </v-responsive>

                      <v-flex xs12 sm12 md12 style="margin-top: 40px">
                        <v-layout justify-center row fill-height align-center>
                          <v-btn
                            color="primary"
                            large
                            @click="searchFormFillupReport"
                            >Submit</v-btn
                          >
                        </v-layout>
                      </v-flex>
                    </v-layout>
                  </v-card>
                </v-flex>

                <!-- <v-flex>

                        <v-card style="padding-top:20px">
                          <v-card-text>
                            <v-menu
                              v-model="payment_from_status"
                              :close-on-content-click="false"
                              :nudge-right="40"
                              lazy
                              transition="scale-transition"
                              offset-y
                              full-width
                              min-width="290px"
                            >
                              <template v-slot:activator="{ on }">
                                <v-text-field
                                  style="padding:0px 10px"
                                  v-model="searchParam.payment_from_date"
                                  label="From Date (Payment)"
                                  prepend-icon="event"
                                  readonly
                                  v-on="on"
                                  clearable
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                v-model="searchParam.payment_from_date"
                                @input="payment_from_status = false"
                              ></v-date-picker>
                            </v-menu>
                            <v-menu
                              v-model="payment_to_status"
                              :close-on-content-click="false"
                              :nudge-right="40"
                              lazy
                              transition="scale-transition"
                              offset-y
                              full-width
                              min-width="290px"
                            >
                              <template v-slot:activator="{ on }">
                                <v-text-field
                                  style="padding:0px 10px"
                                  v-model="searchParam.payment_to_date"
                                  label="To Date (Payment)"
                                  prepend-icon="event"
                                  readonly
                                  v-on="on"
                                  clearable
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                v-model="searchParam.payment_to_date"
                                @input="payment_to_status = false"
                              ></v-date-picker>
                            </v-menu>
                          </v-card-text>
                        </v-card>
                      </v-flex>
                     -->
                      
                  <!-- ==============show data================ --> 
                  <div style="min-width: 100%;max-width: 100%;" class="mt-4" v-if="getFormFillupReportHTML != ``">
                    <div v-html="getFormFillupReportHTML"></div>
                  </div>
                  <!-- <v-flex xs12 sm12 md12>
                  <v-card class="px-3">
                    <v-layout row wrap align-center class="py-3">
                      <v-responsive class="mx-left">
                        
                        </v-responsive>
                        </v-layout>
                    </v-card>
                  </v-flex> -->
                  <!-- ============== close show data ================ -->
              </v-layout>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-layout>
      </v-container>
      <!-- <pre>{{ getSchema }}</pre> -->
    </div>
  </v-container>
</template>

<script>
import commonGet from "@/mixin/common_getters_mixin";

export default {
  mixins: [commonGet],
  $_veeValidate: {
    validator: "new",
  },
  data: () => ({
    export_as: [],
    installment_id:'',
    paper_type_status: false,
    apply_from_status: false,
    apply_to_status: false,
    confirm_from_status: false,
    confirm_to_status: false,
    payment_from_status: false,
    payment_to_status: false,
    data_source_status: false,
    loading: true,
    formData: {},
    panel: [true],
    pagination: {
      rowsPerPage: 50,
      rowsPerPageItems: [50, 100, 500],
    },
    htmls:``,
    search: "",
    details: [],
    examData: "",
    sourceData: [
      { id: 1, tag: "probable_data", name: "Probable Data" },
      { id: 2, tag: "college_confirm_data", name: "Confirm Data" },
      { id: 3, tag: "roll_generated_data", name: "SIF Data" },
    ]
  }),

  computed: {
    getSchema() {
      let data = this.$store.getters.getAllPaymentInstallment;
      if(data){
        return data.filter(val => val.ff_form_fill_up_count > 0);
      }
    },
    searchParam() {
      return this.$store.getters.getFormFillupReportSchema;
    },
    paperType() {
      return this.$store.getters.getPaperTypeData;
    },
    ReportWise() {
      return this.$store.getters.getReportWise;
    },
    installmentText() {
      return function(item) {
        return item.title + ' (Installment - ' + item.no_of_installment + ')';
      }
    },
    getExportType() {
      var export_type = [];
      this.ReportWise.forEach(element => {
        if (element.tag == this.searchParam.report_name) {
          export_type = element.export_type;
        }
      });
      return export_type;
      // return export_type = [{"id":1,"tag":"show_data","name":"ShowData","disabled":false},{"id":2,"tag":".csv","name":"Csv","disabled":false},{"id":3,"tag":".xlsx","name":"Excel","disabled":false},{"id":4,"tag":".pdf","name":"Pdf","disabled":false}];
    },
    getFormFillupReportHTML(){
      return this.$store.getters.getFormFillupReportHTML;
    }
  },

  created() {
    this.getPaymentInstallment();
    this.searchParam.exam_initiate_id = this.getExamInitiateId;
    this.$store.dispatch("fetchReportWise", {
      exam_initiate_id: this.searchParam.exam_initiate_id,
    }).then((response) => {
      this.export_as = response[0].export_type;
    })
    this.$store.dispatch("fetchCPaperTypeList");

  },
  methods: {
    setLimit() {
      const { sortBy, page, rowsPerPage } = this.pagination;
      // this.searchParam.page = 2;
      // this.searchParam.limit = rowsPerPage;
      this.searchParam.sort = sortBy;
      this.searchParam.exam_code = this.getLoggedInExam.code;
      this.searchParam.exam_year = this.getLoggedInYear.year;
      this.searchParam.exam_initiate_id = this.getExamInitiateId;
    },
    getPaymentInstallment() {
      this.initSearchParam();
      this.$store.dispatch("getAllPaymentInstallment", this.searchParam);
    },
    initSearchParam() {
      this.searchParam.exam_code = this.getLoggedInExam.code;
      this.searchParam.exam_year = this.getLoggedInYear.year;
      this.searchParam.exam_initiate_id = this.getExamInitiateId;
    },
    getDataFromApi() {
      this.setLimit();
      this.loading = true;
      this.$store
        // .dispatch("fetchProbableStudentDetailData", this.searchParam)
        .dispatch("fetchFormFillupReportData", this.searchParam)
        .then((data) => {
          this.loading = false;
        });
    },

    searchFormFillupReport() {
      this.$validator.validateAll().then((isValid) => {
        if (isValid) {
          this.loading = true;

          this.getDataFromApi();
        }
      });
    },
  },
  watch: {
    "searchParam.report_name": function (val, oldVal) {

      // console.log('expot',this.export_as);
      let paper_types = [
        "college_wise_form_fillup_count_list",
        "subject_wise_count_list",
        "college_wise_payment_slip",
        "subject_and_college_wise_count_list",
        "candidate_data",
        "college_and_subject_wise_count_list",
        "college_and_course_wise_count_list",
        "college_and_subject_wise_student_count_and_fee_list",
        "degree_group_and_subject_wise_student_count_list"
      ];
      if (val.length && paper_types.includes(val)) {
        this.paper_type_status = false;
      } else {
        this.paper_type_status = true;
      }

      // DATA Source 

      let data_source = [
        "college_wise_form_fillup_count_list"
      ];
      if (val.length && data_source.includes(val)) {
        this.data_source_status = false;
      } else {
        this.data_source_status = true;
      }
    },
  },
  mounted() {
    var vm = this;
    $(document).on('click', '.pagination a', function(event){
      event.preventDefault(); 
      var href = $(this).attr('href');
      var urlParams = new URLSearchParams(href);
      vm.searchParam.page = urlParams.get('page');
      vm.getDataFromApi();
    })
  },
};
</script>

<style scoped>
.teal {
  height: 32px !important;
  width: 70px !important;
  border-radius: 0px !important;
}
.report {
  border-radius: 0px;
}
.mycustomExpansionPanel .v-expansion-panel__header {
  border: 1px solid rgb(255, 255, 255) !important;
}
.nowrap {
  white-space: nowrap;
}
.color-white {
  color: whitesmoke;
}
table {
  border-collapse: collapse !important;
  width: 100%;
}

table.papers th,
table.papers td {
  padding: 8px;
  text-align: left;
  border: 1px solid #ddd;
}
</style>

